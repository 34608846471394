import { ROUTES_CUSTOMERS, ROUTES_EMPLOYEES, ROUTES_PARTNERS } from '@/app/data/route_constants';
import {
  NOTIFICATIONS_CUSTOMERS,
  NOTIFICATIONS_EMPLOYEES,
  NOTIFICATIONS_PARTNERS,
} from '@/app/data/notifications_constants';

export const NOTIFICATION_CUSTOMERS_TO_ROUTE = {
  [NOTIFICATIONS_CUSTOMERS.ANNOUNCEMENT_CREATE]: ROUTES_CUSTOMERS.DASHBOARD,
  [NOTIFICATIONS_CUSTOMERS.DECISION_DOCUMENT_CREATE]: ROUTES_CUSTOMERS.DECISION_DOCUMENTS,
  [NOTIFICATIONS_CUSTOMERS.DECISION_MESSAGE_CREATE]: ROUTES_CUSTOMERS.DECISION_TICKET_SHOW,
  [NOTIFICATIONS_CUSTOMERS.DECISION_PROPOSAL_APPROVED]: ROUTES_CUSTOMERS.DECISION_PROPOSALS,
  [NOTIFICATIONS_CUSTOMERS.DECISION_PROPOSAL_CREATE]: ROUTES_CUSTOMERS.DECISION_PROPOSALS,
  [NOTIFICATIONS_CUSTOMERS.DECISION_PROPOSAL_EDIT_APPROVED]: ROUTES_CUSTOMERS.DECISION_PROPOSALS,
  [NOTIFICATIONS_CUSTOMERS.DECISION_PROPOSAL_RELAUNCHED]: ROUTES_CUSTOMERS.DECISION_PROPOSALS,
  [NOTIFICATIONS_CUSTOMERS.DECISION_RELAUNCHED]: ROUTES_CUSTOMERS.DECISION_PROPOSALS,
  [NOTIFICATIONS_CUSTOMERS.DECISION_PUBLISHED_CUSTOMER]: ROUTES_CUSTOMERS.DECISION_PROPOSALS,
  [NOTIFICATIONS_CUSTOMERS.DOCUMENT_CATEGORY_INVOICE_CREATE]: ROUTES_CUSTOMERS.DOCUMENTS_ALL,
  [NOTIFICATIONS_CUSTOMERS.DOCUMENT_CREATE]: ROUTES_CUSTOMERS.DOCUMENTS_ALL,
  [NOTIFICATIONS_CUSTOMERS.DOCUMENT_PROPOSAL_CREATE]: ROUTES_CUSTOMERS.DECISION_PROPOSALS,
  [NOTIFICATIONS_CUSTOMERS.EXPORT_CREATE]: ROUTES_CUSTOMERS.PROJECT_INFORMATION,
  [NOTIFICATIONS_CUSTOMERS.INSTALLMENT_DOCUMENT_CREATE]: ROUTES_CUSTOMERS.INSTALLMENT_TERMS,
  [NOTIFICATIONS_CUSTOMERS.INSTALLMENT_PUBLISHED_CUSTOMER]: ROUTES_CUSTOMERS.INSTALLMENT_TERMS,
  [NOTIFICATIONS_CUSTOMERS.INSTALLMENT_STATUS_CHANGED_CUSTOMER]: ROUTES_CUSTOMERS.INSTALLMENT_TERMS,
  [NOTIFICATIONS_CUSTOMERS.ISSUE_APPROVAL_CREATE]: ROUTES_CUSTOMERS.ISSUES,
  [NOTIFICATIONS_CUSTOMERS.ISSUE_CREATE]: ROUTES_CUSTOMERS.ISSUES,
  [NOTIFICATIONS_EMPLOYEES.ISSUE_MESSAGE_CREATE]: ROUTES_CUSTOMERS.ISSUE_SHOW_TICKET,
  [NOTIFICATIONS_CUSTOMERS.ISSUE_STATUS_UPDATE]: ROUTES_CUSTOMERS.ISSUES,
  [NOTIFICATIONS_CUSTOMERS.QUESTION_CREATE]: ROUTES_CUSTOMERS.QUESTION_LISTS_SHOW,
  [NOTIFICATIONS_CUSTOMERS.REMINDER_DUE]: ROUTES_CUSTOMERS.DECISION_PROPOSALS,
  [NOTIFICATIONS_CUSTOMERS.SURVEY_PUBLISHED]: ROUTES_CUSTOMERS.DASHBOARD,
  [NOTIFICATIONS_CUSTOMERS.TICKET_CREATE]: ROUTES_CUSTOMERS.TICKETS_SHOW,
  [NOTIFICATIONS_CUSTOMERS.TICKET_DOCUMENT_CREATE]: ROUTES_CUSTOMERS.TICKETS_SHOW,
  [NOTIFICATIONS_CUSTOMERS.TICKET_MESSAGE_CREATE]: ROUTES_CUSTOMERS.TICKETS_SHOW,
  [NOTIFICATIONS_CUSTOMERS.TICKET_MESSAGE_MOVE]: ROUTES_CUSTOMERS.TICKETS_SHOW,
};

export const NOTIFICATION_EMPLOYEES_TO_ROUTE = {
  [NOTIFICATIONS_EMPLOYEES.ANNOUNCEMENT_CREATE]: ROUTES_EMPLOYEES.PROJECT_ANNOUNCEMENTS,
  [NOTIFICATIONS_EMPLOYEES.DECISION_DOCUMENT_CREATE]: ROUTES_EMPLOYEES.PROJECT_DECISION_DOCUMENTS,
  [NOTIFICATIONS_EMPLOYEES.DECISION_MESSAGE_CREATE]: ROUTES_EMPLOYEES.PROJECT_DECISION_TICKETS_SHOW,
  [NOTIFICATIONS_EMPLOYEES.DECISION_PROPOSAL_APPROVED]: ROUTES_EMPLOYEES.PROJECT_DECISION_PROPOSALS,
  [NOTIFICATIONS_EMPLOYEES.DECISION_PROPOSAL_CREATE]: ROUTES_EMPLOYEES.PROJECT_DECISION_PROPOSALS,
  [NOTIFICATIONS_EMPLOYEES.DECISION_RESPONSIBLE_UPDATE]: ROUTES_EMPLOYEES.PROJECT_DECISION_PROPOSALS,
  [NOTIFICATIONS_EMPLOYEES.DOCUMENT_CREATE]: ROUTES_EMPLOYEES.PROJECT_DOCUMENTS,
  [NOTIFICATIONS_EMPLOYEES.DOCUMENT_PROPOSAL_CREATE]: ROUTES_EMPLOYEES.PROJECT_DECISION_PROPOSALS,
  [NOTIFICATIONS_EMPLOYEES.DECISION_PROPOSAL_REQUESTED_CHANGES]: ROUTES_EMPLOYEES.PROJECT_DECISION_PROPOSALS,
  [NOTIFICATIONS_EMPLOYEES.DECISION_NOT_INTERESTED]: ROUTES_EMPLOYEES.PROJECT_DECISION_PROPOSALS,
  [NOTIFICATIONS_EMPLOYEES.DECISION_PROPOSAL_SIGNED]: ROUTES_EMPLOYEES.PROJECT_DECISION_PROPOSALS,
  [NOTIFICATIONS_EMPLOYEES.EXPORT_CREATE]: ROUTES_EMPLOYEES.PROJECT_DECISIONS,
  [NOTIFICATIONS_EMPLOYEES.EMAIL_CONVERSATION_PROJECT_CREATE]: ROUTES_EMPLOYEES.PROJECT_EMAIL_CONVERSATIONS_SHOW,
  [NOTIFICATIONS_EMPLOYEES.EMAIL_CONVERSATION_CLIENT_CREATE]: ROUTES_EMPLOYEES.CLIENT_EMAIL_CONVERSATIONS_SHOW,
  [NOTIFICATIONS_EMPLOYEES.ISSUE_APPROVED]: ROUTES_EMPLOYEES.PROJECT_ISSUE_UNIT_ISSUES,
  [NOTIFICATIONS_EMPLOYEES.ISSUE_CREATE]: ROUTES_EMPLOYEES.PROJECT_ISSUE_INBOX,
  [NOTIFICATIONS_EMPLOYEES.ISSUE_MESSAGE_CREATE]: ROUTES_EMPLOYEES.PROJECT_ISSUE_UNIT_SHOW_TICKET,
  [NOTIFICATIONS_EMPLOYEES.ISSUE_REJECTED]: ROUTES_EMPLOYEES.PROJECT_ISSUE_UNIT_ISSUES,
  [NOTIFICATIONS_EMPLOYEES.QUESTION_CREATE]: ROUTES_EMPLOYEES.PROJECT_QUESTION_LISTS_SHOW,
  [NOTIFICATIONS_EMPLOYEES.SURVEY_ANSWER_CREATE]: ROUTES_EMPLOYEES.PROJECT_SURVEYS,
  [NOTIFICATIONS_EMPLOYEES.TICKET_CREATE]: ROUTES_EMPLOYEES.PROJECT_TICKETS_SHOW,
  [NOTIFICATIONS_EMPLOYEES.TICKET_DOCUMENT_CREATE]: ROUTES_EMPLOYEES.PROJECT_TICKETS_SHOW,
  [NOTIFICATIONS_EMPLOYEES.TICKET_MENTION_CREATE]: ROUTES_EMPLOYEES.PROJECT_TICKETS_SHOW,
  [NOTIFICATIONS_EMPLOYEES.TICKET_MESSAGE_CREATE]: ROUTES_EMPLOYEES.PROJECT_TICKETS_SHOW,
  [NOTIFICATIONS_EMPLOYEES.TICKET_RESPONSIBLE_UPDATE]: ROUTES_EMPLOYEES.PROJECT_TICKETS_SHOW,
  [NOTIFICATIONS_EMPLOYEES.TODO_ASSIGN]: ROUTES_EMPLOYEES.PROJECT_TODO_LISTS,
};

export const NOTIFICATION_PARTNERS_TO_ROUTE = {
  [NOTIFICATIONS_PARTNERS.DECISION_DOCUMENT_CREATE]: ROUTES_PARTNERS.PROJECT_DECISION_DOCUMENTS,
  [NOTIFICATIONS_PARTNERS.DECISION_MESSAGE_CREATE]: ROUTES_PARTNERS.PROJECT_DECISION_TICKETS_SHOW,
  [NOTIFICATIONS_PARTNERS.DECISION_PROPOSAL_APPROVED]: ROUTES_PARTNERS.PROJECT_DECISION_PROPOSALS,
  [NOTIFICATIONS_PARTNERS.DECISION_PROPOSAL_REQUESTED_CHANGES]: ROUTES_PARTNERS.PROJECT_DECISION_PROPOSALS,
  [NOTIFICATIONS_PARTNERS.DECISION_NOT_INTERESTED]: ROUTES_PARTNERS.PROJECT_DECISION_PROPOSALS,
  [NOTIFICATIONS_PARTNERS.DECISION_PROPOSAL_CREATE]: ROUTES_PARTNERS.PROJECT_DECISION_PROPOSALS,
  [NOTIFICATIONS_PARTNERS.DECISION_PUBLISHED_PARTNERS]: ROUTES_PARTNERS.PROJECT_DECISION_TICKETS,
  [NOTIFICATIONS_PARTNERS.DOCUMENT_PROPOSAL_CREATE]: ROUTES_PARTNERS.PROJECT_DECISION_PROPOSALS,
  [NOTIFICATIONS_PARTNERS.EXPORT_CREATE]: ROUTES_PARTNERS.PROJECT_DECISIONS,
  [NOTIFICATIONS_PARTNERS.ISSUE_LIST_PUBLISHED]: ROUTES_PARTNERS.PROJECT_ISSUE_LIST_TYPE_UNITS,
  [NOTIFICATIONS_PARTNERS.ISSUE_STATUS_UPDATE]: ROUTES_PARTNERS.PROJECT_ISSUE_UNIT_ISSUES,
  [NOTIFICATIONS_PARTNERS.REMINDER_DUE]: ROUTES_PARTNERS.PROJECT_DECISION_TICKETS,
  [NOTIFICATIONS_PARTNERS.UNIT_VISIBLED]: ROUTES_PARTNERS.PROJECT_DECISION_TICKETS,
};

export const NOTIFICATIONS_TO_ROUTES = {
  ...NOTIFICATION_CUSTOMERS_TO_ROUTE,
  ...NOTIFICATION_EMPLOYEES_TO_ROUTE,
  ...NOTIFICATION_PARTNERS_TO_ROUTE,
};

export const NOTIFICATION_CUSTOMERS_GROUP_TO_ROUTE = {
  [NOTIFICATIONS_CUSTOMERS.ANNOUNCEMENT_CREATE]: ROUTES_CUSTOMERS.DASHBOARD,
  [NOTIFICATIONS_CUSTOMERS.DECISION_DOCUMENT_CREATE]: ROUTES_CUSTOMERS.DECISION_DOCUMENTS,
  [NOTIFICATIONS_CUSTOMERS.DECISION_MESSAGE_CREATE]: ROUTES_CUSTOMERS.DECISION_TICKET_SHOW,
  [NOTIFICATIONS_CUSTOMERS.DECISION_PROPOSAL_CREATE]: ROUTES_CUSTOMERS.DECISION_PROPOSALS,
  [NOTIFICATIONS_CUSTOMERS.DECISION_PROPOSAL_RELAUNCHED]: ROUTES_CUSTOMERS.DECISION_PROPOSALS,
  [NOTIFICATIONS_CUSTOMERS.DECISION_RELAUNCHED]: ROUTES_CUSTOMERS.DECISION_PROPOSALS,
  [NOTIFICATIONS_CUSTOMERS.DECISION_PUBLISHED_CUSTOMER]: ROUTES_CUSTOMERS.DECISION_PROPOSALS,
  [NOTIFICATIONS_CUSTOMERS.DOCUMENT_CATEGORY_INVOICE_CREATE]: ROUTES_CUSTOMERS.DOCUMENTS_ALL,
  [NOTIFICATIONS_CUSTOMERS.DOCUMENT_CREATE]: ROUTES_CUSTOMERS.DOCUMENTS_ALL,
  [NOTIFICATIONS_CUSTOMERS.DOCUMENT_PROPOSAL_CREATE]: ROUTES_CUSTOMERS.DECISION_PROPOSALS,
  [NOTIFICATIONS_CUSTOMERS.EXPORT_CREATE]: ROUTES_CUSTOMERS.PROJECT_INFORMATION,
  [NOTIFICATIONS_CUSTOMERS.INSTALLMENT_PUBLISHED_CUSTOMER]: ROUTES_CUSTOMERS.INSTALLMENT_TERMS,
  [NOTIFICATIONS_CUSTOMERS.INSTALLMENT_STATUS_CHANGED_CUSTOMER]: ROUTES_CUSTOMERS.INSTALLMENT_TERMS,
  [NOTIFICATIONS_CUSTOMERS.ISSUE_CREATE]: ROUTES_CUSTOMERS.ISSUES,
  [NOTIFICATIONS_EMPLOYEES.ISSUE_MESSAGE_CREATE]: ROUTES_CUSTOMERS.ISSUE_SHOW_TICKET,
  [NOTIFICATIONS_CUSTOMERS.ISSUE_STATUS_UPDATE]: ROUTES_CUSTOMERS.ISSUES,
  [NOTIFICATIONS_CUSTOMERS.QUESTION_CREATE]: ROUTES_CUSTOMERS.QUESTION_LISTS_SHOW,
  [NOTIFICATIONS_CUSTOMERS.REMINDER_DUE]: ROUTES_CUSTOMERS.DECISION_PROPOSALS,
  [NOTIFICATIONS_CUSTOMERS.SURVEY_PUBLISHED]: ROUTES_CUSTOMERS.DASHBOARD,
  [NOTIFICATIONS_CUSTOMERS.TICKET_CREATE]: ROUTES_CUSTOMERS.TICKETS_SHOW,
  [NOTIFICATIONS_CUSTOMERS.TICKET_DOCUMENT_CREATE]: ROUTES_CUSTOMERS.TICKETS_SHOW,
  [NOTIFICATIONS_CUSTOMERS.TICKET_MESSAGE_CREATE]: ROUTES_CUSTOMERS.TICKETS_SHOW,
  [NOTIFICATIONS_CUSTOMERS.TICKET_MESSAGE_MOVE]: ROUTES_CUSTOMERS.TICKETS_SHOW,
};

export const NOTIFICATION_EMPLOYEES_GROUP_TO_ROUTE = {
  [NOTIFICATIONS_EMPLOYEES.ANNOUNCEMENT_CREATE]: ROUTES_EMPLOYEES.PROJECT_ANNOUNCEMENTS,
  [NOTIFICATIONS_EMPLOYEES.DECISION_DOCUMENT_CREATE]: ROUTES_EMPLOYEES.PROJECT_DECISION_DOCUMENTS,
  [NOTIFICATIONS_EMPLOYEES.DECISION_MESSAGE_CREATE]: ROUTES_EMPLOYEES.PROJECT_DECISION_TICKETS_SHOW,
  [NOTIFICATIONS_EMPLOYEES.DECISION_PROPOSAL_APPROVED]: ROUTES_EMPLOYEES.PROJECT_DECISION_PROPOSALS,
  [NOTIFICATIONS_EMPLOYEES.DECISION_PROPOSAL_CREATE]: ROUTES_EMPLOYEES.PROJECT_DECISION_PROPOSALS,
  [NOTIFICATIONS_EMPLOYEES.DECISION_RESPONSIBLE_UPDATE]: ROUTES_EMPLOYEES.PROJECT_DECISIONS,
  [NOTIFICATIONS_EMPLOYEES.DOCUMENT_CREATE]: ROUTES_EMPLOYEES.PROJECT_DOCUMENTS,
  [NOTIFICATIONS_EMPLOYEES.DOCUMENT_PROPOSAL_CREATE]: ROUTES_EMPLOYEES.PROJECT_DECISION_PROPOSALS,
  [NOTIFICATIONS_EMPLOYEES.DECISION_PROPOSAL_REQUESTED_CHANGES]: ROUTES_EMPLOYEES.PROJECT_DECISION_PROPOSALS,
  [NOTIFICATIONS_EMPLOYEES.DECISION_PROPOSAL_SIGNED]: ROUTES_EMPLOYEES.PROJECT_DECISION_PROPOSALS,
  [NOTIFICATIONS_EMPLOYEES.DECISION_NOT_INTERESTED]: ROUTES_EMPLOYEES.PROJECT_DECISIONS,
  [NOTIFICATIONS_EMPLOYEES.ISSUE_CREATE]: ROUTES_EMPLOYEES.PROJECT_ISSUE_INBOX,
  [NOTIFICATIONS_EMPLOYEES.ISSUE_MESSAGE_CREATE]: ROUTES_EMPLOYEES.PROJECT_ISSUE_UNIT_SHOW_TICKET,
  [NOTIFICATIONS_EMPLOYEES.EMAIL_CONVERSATION_PROJECT_CREATE]: ROUTES_EMPLOYEES.PROJECT_EMAIL_CONVERSATIONS,
  [NOTIFICATIONS_EMPLOYEES.EMAIL_CONVERSATION_CLIENT_CREATE]: ROUTES_EMPLOYEES.CLIENT_EMAIL_CONVERSATIONS,
  [NOTIFICATIONS_EMPLOYEES.QUESTION_CREATE]: ROUTES_EMPLOYEES.PROJECT_QUESTION_LISTS_SHOW,
  [NOTIFICATIONS_EMPLOYEES.SURVEY_ANSWER_CREATE]: ROUTES_EMPLOYEES.PROJECT_SURVEYS,
  [NOTIFICATIONS_EMPLOYEES.TICKET_CREATE]: ROUTES_EMPLOYEES.PROJECT_TICKETS_SHOW,
  [NOTIFICATIONS_EMPLOYEES.TICKET_DOCUMENT_CREATE]: ROUTES_EMPLOYEES.PROJECT_TICKETS_SHOW,
  [NOTIFICATIONS_EMPLOYEES.TICKET_MENTION_CREATE]: ROUTES_EMPLOYEES.PROJECT_TICKETS_SHOW,
  [NOTIFICATIONS_EMPLOYEES.TICKET_MESSAGE_CREATE]: ROUTES_EMPLOYEES.PROJECT_TICKETS_SHOW,
  [NOTIFICATIONS_EMPLOYEES.TICKET_RESPONSIBLE_UPDATE]: ROUTES_EMPLOYEES.PROJECT_TICKETS_SHOW,
  [NOTIFICATIONS_EMPLOYEES.TODO_ASSIGN]: ROUTES_EMPLOYEES.PROJECT_TODO_LISTS,
};

export const NOTIFICATION_PARTNERS_GROUP_TO_ROUTE = {
  [NOTIFICATIONS_PARTNERS.DECISION_DOCUMENT_CREATE]: ROUTES_PARTNERS.PROJECT_DECISION_DOCUMENTS,
  [NOTIFICATIONS_PARTNERS.DECISION_MESSAGE_CREATE]: ROUTES_PARTNERS.PROJECT_DECISION_TICKETS_SHOW,
  [NOTIFICATIONS_PARTNERS.DECISION_PROPOSAL_APPROVED]: ROUTES_PARTNERS.DECISION_PROPOSALS,
  [NOTIFICATIONS_PARTNERS.DECISION_PROPOSAL_CREATE]: ROUTES_PARTNERS.DECISION_PROPOSALS,
  [NOTIFICATIONS_PARTNERS.DECISION_PROPOSAL_REQUESTED_CHANGES]: ROUTES_PARTNERS.PROJECT_DECISION_PROPOSALS,
  [NOTIFICATIONS_PARTNERS.DECISION_PUBLISHED_PARTNERS]: ROUTES_PARTNERS.PROJECT_DECISIONS,
  [NOTIFICATIONS_PARTNERS.DOCUMENT_PROPOSAL_CREATE]: ROUTES_PARTNERS.DECISION_PROPOSALS,
  [NOTIFICATIONS_PARTNERS.EXPORT_CREATE]: ROUTES_PARTNERS.PROJECT_DECISIONS,
  [NOTIFICATIONS_PARTNERS.ISSUE_LIST_PUBLISHED]: ROUTES_PARTNERS.PROJECT_ISSUE_LIST_TYPE_UNITS,
  [NOTIFICATIONS_PARTNERS.ISSUE_STATUS_UPDATE]: ROUTES_PARTNERS.PROJECT_ISSUE_UNIT_ISSUES,
  [NOTIFICATIONS_PARTNERS.REMINDER_DUE]: ROUTES_PARTNERS.PROJECT_DECISION_PROPOSALS,
  [NOTIFICATIONS_PARTNERS.UNIT_VISIBLED]: ROUTES_PARTNERS.PROJECT_DECISIONS,
};

export const NOTIFICATION_GROUP_TO_ROUTE = {
  ...NOTIFICATION_CUSTOMERS_GROUP_TO_ROUTE,
  ...NOTIFICATION_EMPLOYEES_GROUP_TO_ROUTE,
  ...NOTIFICATION_PARTNERS_GROUP_TO_ROUTE,
};
